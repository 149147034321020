import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.realisations-slider-block');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      new Swiper(carousel, {
        slidesPerView: 'auto',
        spaceBetween: 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    });
  }
});
